import utility from '@/common/utility'

const G21SpreadInfo = {
  sheetName: 'G21',
  columns: [
    {
      label: '序号',
      dataKey: 'serialNo',
      editable: false,
      width: '50'
    },
    {
      label: '工程名称',
      dataKey: 'engineeringListName',
      editable: false,
      width: '100',
      children: [
        {
          label: '工程类别',
          dataKey: 'parentEngineeringListName',
          editable: false,
          width: '150'
        },
        {
          label: '工程名称',
          dataKey: 'engineeringListName',
          editable: false,
          width: '200'
        }
      ]
    },
    {
      label: '单位',
      dataKey: 'unit',
      editable: false,
      width: '60'
    },
    {
      label: '合同数量',
      dataKey: 'num',
      editable: false,
      width: '120'
    },
    {
      label: '第一年度',
      dataKey: 'firstYear',
      editable: false,
      children: [
        {
          label: '工程量',
          dataKey: 'firstYearNum',
          validator (row) {
            if (row.firstYearNum !== undefined && row.firstYearNum !== null && row.firstYearNum !== '') {
              const len = row.unit.split('\\').length
              const firstYearNumList = row.firstYearNum.toString().split('\\')
              if (len !== firstYearNumList.length) {
                return false
              }
              if (!firstYearNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          editable: true,
          width: '140'
        },
        {
          label: '占合同（%）',
          dataKey: 'firstYearPercent',
          editable: false,
          validator (row) {
            if (row.firstYearPercent !== undefined && row.firstYearPercent !== null && row.firstYearPercent !== '') {
              const len = row.unit.split('\\').length
              const firstYearPercentList = row.firstYearPercent.toString().split('\\')
              if (len !== firstYearPercentList.length) {
                return false
              }
              if (!firstYearPercentList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          formula (row) {
            const numList = row.num.split('\\')
            const firstYearNumList = row.firstYearNum.toString().split('\\')
            if (numList.length === firstYearNumList.length) {
              return firstYearNumList.map((firstYearNum, index) => {
                return utility.floatMultiple(utility.floatDivide(firstYearNum, numList[index]), 100).toFixed(3)
              }).join('\\')
            } else {
              return ''
            }
          },
          width: '140'
        }
      ]
    },
    {
      label: '第二年度',
      dataKey: 'secondYear',
      editable: false,
      children: [
        {
          label: '工程量',
          dataKey: 'secondYearNum',
          editable: true,
          validator (row) {
            if (row.secondYearNum !== undefined && row.secondYearNum !== null && row.secondYearNum !== '') {
              const len = row.unit.split('\\').length
              const secondYearNumList = row.secondYearNum.toString().split('\\')
              if (len !== secondYearNumList.length) {
                return false
              }
              if (!secondYearNumList.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '140'
        },
        {
          label: '占合同（%）',
          dataKey: 'secondYearPercent',
          editable: false,
          validator (row) {
            if (row.secondYearPercent !== undefined && row.secondYearPercent !== null && row.secondYearPercent !== '') {
              const len = row.unit.split('\\').length
              console.log('secondYearPercent', row.secondYearPercent)
              const secondYearPercentList = row.secondYearPercent.toString().split('\\')
              if (len !== secondYearPercentList.length) {
                return false
              }
              if (!secondYearPercentList.every(item => !isNaN(item))) {
                console.log(secondYearPercentList)
                console.log('hello')
                return false
              }
            }
            return true
          },
          formula (row) {
            const numList = row.num.split('\\')
            const secondYearNumList = row.secondYearNum.toString().split('\\')
            if (numList.length === secondYearNumList.length) {
              return secondYearNumList.map((secondYearNum, index) => {
                return utility.floatMultiple(utility.floatDivide(secondYearNum, numList[index]), 100).toFixed(3)
              }).join('\\')
            } else {
              return ''
            }
          },
          width: '140'
        }
      ]
    },
    {
      label: '第三年度',
      dataKey: 'thirdYear',
      editable: false,
      children: [
        {
          label: '工程量',
          dataKey: 'thirdYearNum',
          validator (row) {
            const firstYearNumList = row.firstYearNum.toString().split('\\')
            const secondYearNumList = row.secondYearNum.toString().split('\\')
            console.log('first-second list', firstYearNumList, secondYearNumList)
            const len = row.unit.split('\\').length
            if ((!row.firstYearNum && !row.secondYearNum) || (len === firstYearNumList.length && len === secondYearNumList.length)) {
              return true
            }
            return false
          },
          formula (row) {
            const firstYearNumList = row.firstYearNum.toString().split('\\')
            const secondYearNumList = row.secondYearNum.toString().split('\\')
            const len = row.unit.split('\\').length
            if (len !== firstYearNumList.length || len !== secondYearNumList.length) {
              return ''
            } else {
              return row.num.toString().split('\\').map((num, index) => {
                const sum = utility.floatAdd(firstYearNumList[index], secondYearNumList[index])
                return utility.floatSubstract(num, sum)
              }).join('\\')
            }
          },
          editable: false,
          width: '140'
        },
        {
          label: '占合同（%）',
          dataKey: 'thirdYearPercent',
          editable: false,
          formula (row) {
            const firstYearPercentList = row.firstYearPercent.toString().split('\\')
            const secondYearPercentList = row.secondYearPercent.toString().split('\\')
            const len = row.unit.split('\\').length
            if (len !== firstYearPercentList.length || len !== secondYearPercentList.length) {
              return ''
            } else {
              return row.num.toString().split('\\').map((num, index) => {
                console.log(num)
                const sum = utility.floatAdd(firstYearPercentList[index], secondYearPercentList[index]).toFixed(3)
                return utility.floatSubstract(100, sum)
              }).join('\\')
            }
          },
          width: '140'
        }
      ]
    }
  ]
}

export default G21SpreadInfo
