<template>
  <div class="g21">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
          <el-button v-if="errorKeys" type="primary" @click="handleErrorClick">下一错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import auth from '@/common/auth'
import G21SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G21SpreadInfo'
import G21Model from '@/model/G21Model'
import g21Service from '@/services/g21Service'
// import utility from '@/common/utility'
import { mapGetters } from 'vuex'
import MySpread from '@/components/Spread/MySpreadOld'

export default {
  name: 'g21',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      defaultDataList: [],
      spreadInfo: G21SpreadInfo
    }
  },
  computed: {
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      // 检查是否有错误
      this.$refs.mySpread.validate()
      this.$myLoading()
      if (this.$refs.mySpread.errorKeys.length === 0) {
        let data = this.$refs.mySpread.getData().map((item, index) => {
          item.firstYearNum = item.firstYearNum.toString()
          item.firstYearPercent = item.firstYearPercent.toString()
          item.secondYearNum = item.secondYearNum.toString()
          item.secondYearPercent = item.secondYearPercent.toString()
          item.thirdYearNum = item.thirdYearNum.toString()
          item.thirdYearPercent = item.thirdYearPercent.toString()
          item.planStatExaminationGuid = this.planStatExaminationGuid
          item.engineeringListGuid = this.defaultDataList[index].engineeringListGuid
          item.tenderGuid = this.tenderGuid
          return new G21Model(item)
        }).filter(item => item.firstYearNum !== '' && item.secondYearNum !== '')
        g21Service.batchAdd(data)
          .then(res => {
            this.$loading().close()
            this.$message({
              type: 'success',
              message: '保存成功！'
            })
            this.$router.push('/g21Index')
          })
          .catch(err => {
            this.$loading().close()
            console.log(err)
            this.$message({
              type: 'error',
              message: '保存失败，请重试！'
            })
          })
      } else {
        this.$loading().close()
        this.$message({
          type: 'error',
          message: '校验失败，请检查您的输入！'
        })
      }
    },
    handleErrorClick () {
      let rowIndex = this.errorMap[this.errorKeys[this.currentErrorIndex]].rowIndex
      this.worksheet.showRow(rowIndex, GC.Spread.Sheets.VerticalPosition.top)
      // this.currentErrorIndex = (this.currentErrorIndex + 1) % this.errorKeys.length
    },
    getDefaultData () {
      g21Service.getDefaultData(this.tenderGuid, this.planStatExaminationGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.defaultDataList = res.data.data.map((item, i) => {
              item.serialNo = i + 1
              return item
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getDefaultData()
  }
}
</script>

<style scoped lang="scss">
.g21 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
